import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import HomePage from "./HomePage"
import Reseller from "./Reseller"
import Tenant from "./Tenant"
import EditUser from "./EditUser"
import Users from "./Users"
import EditTenant from "./EditTenant"
import Licences from "./Licences"
import BuyLicence from "./BuyLicence"
import AddNewTenant from "./AddNewTenant"
import TenantUsers from "./TenantUsers"
import AddNewUser from "./AddNewUser"
import TenantLicences from "./TenantLicences"
import DefaultSettings from "./DefaultSettings"
import Footer from "./Footer"
import User from "./User"
import Error from "./Error"
import Support from "./Support"
import SuperTenant from './SuperTenant'

import './App.css'

//IMPORTANT!!
//Signin via the https://signin-va.cloudcti.nl/signin/onelogin ONLY works if the app runs in a *.cloudcti.nl domain
//It will NOT work in a localhost environment, and it will NOT work in an *.azurewebsites.net environment
//For these latter purposes, the debug lines in the App() can be used to force a user

function App() {

  //sessionStorage.setItem("username","Arash Reseller")
  //sessionStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJwYXJ2YS5hcmFzaEBnbWFpbC5jb20iLCJTaWduaW5QYXNzd29yZCI6IldzUlVWYkdKVWFXZUMvalkxTTc1aE9YZjMyWWJ4K3l0TUdrcnpKalZpZ1NnRlJKY25vbDUwVGZHUTB2bUVMSlRpVmZJQlpFRWNTS2dzYllDSW55UUtRb1FaVGJ2a2VGZTJWZHRYalUvS0h1WGdDVmtmVFU5MmJyc2ZISjBnN28vIiwiU2lnbmluQXBwbGljYXRpb24iOiJSZXNlbGxlclBvcnRhbCIsIlRlbmFudEd1aWQiOiJUZW5hbnRfMjQxYzdjMWMtZTgwNC00MWE4LTk5ODItZmNlM2Q3NDkxODE0IiwiVGVuYW50TmFtZSI6IkNyZWFyIFJhdGUgVGVzdCBDb21wYW55IiwiVXNlckd1aWQiOiJUZW5hbnRVc2VyXzEwZjlhZmNkLWYwZTYtNDFiOC1iN2E5LTA4ODMwNWYxZTEzMCIsIlVzZXJOYW1lIjoiQXJhc2giLCJVc2VySXNBZG1pbiI6IjEiLCJVc2VySXNUZW5hbnRBZG1pbiI6IjEiLCJFbWFpbCI6InBhcnZhLmFyYXNoQGdtYWlsLmNvbSIsIlJlc2VsbGVyT3duZXJHdWlkIjoiUmVzZWxsZXJfNzQ0ODk2RTMtRjY4MS00NDk5LTgyMUEtMkE5ODFBOThEMDgzIiwiSXNTdXBlclRlbmFudCI6IjEiLCJTdXBlclRlbmFudFJlc2VsbGVyR3VpZCI6IlJlc2VsbGVyXzc0NDg5NkUzLUY2ODEtNDQ5OS04MjFBLTJBOTgxQTk4RDA4MyIsIlJvbGVzIjoiVXNlcixSZXNlbGxlciIsImV4cCI6MTc0NjI2OTY3MSwiaXNzIjoic2lnbmluLXZhIiwiYXVkIjoiQ2xvdWRDVEkyMDIyIn0.2E3tBRWoZBr7Dy9coa5dZ6vFXeguH1rrJjwsZkVVTiE")

  const token = Cookies.get("resellerportal")
  //const token = sessionStorage.getItem("token")
  if (token) {
    try
    {
      const decodedToken = jwtDecode(token);
      
      sessionStorage.setItem("username", decodedToken.UserName);
      sessionStorage.setItem("token", token);

      // Log in as a reseller(super tenant)
      if(decodedToken.IsSuperTenant === "1")
      {
        console.log("Super tenant account detected.")
        sessionStorage.setItem("IsSuperTenant","1")
        sessionStorage.setItem("resellerguid",decodedToken.SuperTenantResellerGuid)
        sessionStorage.setItem("SuperTenantGuid",decodedToken.TenantGuid)
      }

    }

    catch (error)
    {
      console.error("Error decoding token:", error);
    }
  }

  if (sessionStorage.getItem("token"))
  {
      return (
        <Router>
          <Routes>
            <Route path="/edittenant" element={<EditTenant/>} />
            <Route path="/tenant" element={<Tenant/>}/>
            <Route path="/reseller" element={<Reseller/>} />
       x    <Route path="/" element={<HomePage/>} />
            <Route path="/edituser" element={<EditUser/>} />
            <Route path="/users" element={<Users/>} />
            <Route path="/licences" element={<Licences/>} />
            <Route path="/buylicence" element={<BuyLicence/>} />
            <Route path="/addnewtenant" element={<AddNewTenant/>} />
            <Route path="/tenantusers" element={<TenantUsers/>} />
            <Route path="/addnewuser" element={<AddNewUser/>} />
            <Route path="/tenantlicences" element={<TenantLicences/>} />
            <Route path="/defaultsettings" element={<DefaultSettings/>} />
            <Route path="/footer" element={<Footer/>}/>
            <Route path="/user" element={<User/>}/>
            <Route path="/error" element={<Error/>}/>
            <Route path="/support" element={<Support/>}/>
            <Route path="/supertenant" element={<SuperTenant/>}/> 
          </Routes>
        </Router>
      );
    }

    else
    {   
      //const redirecturl = window.location.href.replaceAll(":","%3A").replaceAll("/","%2F").replaceAll("&","%26")   
      const redirecturl = window.location.protocol + '//' + window.location.host  
      window.location.href = "https://signin-va.cloudcti.nl/signin/onelogin?clientid=resellerportal&redirecturl=" + redirecturl
    }  
  }

export default App;
